import React, { useState } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { FilterItem, Refinements } from '../../../common/types'
import { useGlobalDispatch, useGlobalState, saveFacets } from '../../../context/GlobalDataContext'

export const StandardFilter = ({ showMore, items, refine, attribute, limit }: Refinements) => {
  const state = useGlobalState()
  const dispatch = useGlobalDispatch()
  const [extended, setExtended] = useState(false)

  const itemSelected = (item: FilterItem) => {
    refine(item.value)
    saveFacets({ state, dispatch, attribute, value: item.value })
  }

  return (
    <div>
      <ul className="filter">
        {items.map(
          (item: FilterItem, i: number) =>
            (i < limit || extended) && (
              <li key={`${item.label}-${i}`}>
                <input
                  type="checkbox"
                  data-testid={`standard-filter-${i}`}
                  checked={item.isRefined}
                  onChange={() => {
                    itemSelected(item)
                  }}
                />
                {item.label} ({item.count})
              </li>
            )
        )}
      </ul>
      {showMore && items.length > limit && (
        <button
          onClick={() => {
            setExtended(!extended)
          }}
          className="ais-RefinementList-showMore"
        >
          {extended ? 'Show less' : 'Show more'}
        </button>
      )}
    </div>
  )
}

export default connectRefinementList(StandardFilter)
